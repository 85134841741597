//    Introduction
//    Welcome to the CSS file! This is subjectively the most exciting code
//    of the website. Here we bring together the visual style of the website.

//    Our CSS is organized using Harry Roberts' (csswizardry.com) Inverted
//    Triangle CSS (ITCSS) organizational approach. This method is mixed with
//    Block Element Modifier (BEM) naming convention for class names throughout
//    the stylesheet.

//    Table of Contents:
// 1. Settings
// 2. Tools
// 3. Generic
// 4. Elements
// 5. Objects
// 6. Components
// 7. Vendors
// 8. Utilities

// =====================================================================
// 1. Settings
//    When using a preprocessor, settings are used to define variables.
//    This puts variables at the top of the cascade so they are accessible
//    to all partials further down. No CSS selectors should be generated
//    by the preprocessor from partials in this section.

//    When not using a preprocessor, the settings area is a good place
//    to write comments describing the colors and font stacks as a guide.
//    If using features such as custom properties, those that are global
//    custom properties should be described in this section.

//    Most likely, these will be imported via @use in other files, not
//    included in this entry point file.
@forward "./settings";

// =====================================================================
// 2. Tools
//    This section is specifically for preprocessor global mixins and
//    functions. No CSS should be generated by the preprocessor from
//    partials in this section.

//    Most likely, these will be imported via @use in other files, not
//    included in this entry point file.
@forward "./tools";

// =====================================================================
// 3. Generic
//    Here we define any generic styles that are not specific to the styles of
//    the site. This section should include any Reset or Normalize libraries
//    and any preferential base styles for elements. There shouldn’t be any
//    classes or ids used in this section.
@forward "./generic";

// =====================================================================
// 4. Elements
//    Styling for bare HTML elements (like H1, A, etc.). These come with
//    default styling from the browser so we can redefine them here.
@forward "./elements";

// =====================================================================
// 5. Objects
//    Class-based selectors which define undecorated design patterns,
//    for example media object known from OOCSS.
@forward "./objects";

// =====================================================================
// 6. Components
//    Specific UI components. This is where majority of our work takes place
//    and our UI components are often composed of Objects and Components
@forward './components-body';
@forward './components-left';
@forward './components-right';

// =====================================================================
// 7. Vendors
//    Whenever third party styles are needed add them to this section,
//    below the Components, but above the Utilities. This allows them
//    to live alongside our styles but still be overridden if needed by
//    a utility class.

// =====================================================================
// 8. Utilities
//    Utilities and helper classes with ability to override anything
//    which goes before in the triangle, eg. hide helper class
@forward './utilities';
