:root {
  --black: #000;
  --white: #fff;
  --text-color: #09203f;
  --light-border: #e8e8e8;
  --medium-background: whitesmoke;
  --medium-primary: #283c86;
  --faded-primary: #315b73;
  --solid-primary: #2f5676;
  --solid-primary-secondary: #376f67;
  --solid-secondary: #3e8957;
  --medium-secondary: #45a247;
  --faded-secondary: #397960;
  --medium-gradient: to left, var(--medium-secondary), var(--medium-primary);
  --faded-medium-gradient: to left, var(--faded-secondary), var(--medium-primary);
  --hero-text-gradient: to right, var(--faded-secondary), var(--medium-primary);
}

body {
  margin: 0;
}

html, body {
  scroll-behavior: smooth;
}

.body {
  flex-direction: column;
  display: flex;
}

@media (min-width: 800px) {
  .body {
    flex-direction: row;
    gap: 1rem;
  }
}

.left {
  box-sizing: border-box;
  flex-basis: auto;
  position: relative;
}

@media (min-width: 800px) {
  .left {
    flex-basis: 16rem;
  }
}

.header {
  text-align: center;
  box-sizing: border-box;
  color: var(--text-color);
  margin-top: 3rem;
}

@media (min-width: 800px) {
  .header {
    width: 16rem;
    margin-top: 0;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
  }
}

.header__primary-link {
  color: inherit;
  text-decoration: none;
}

.title {
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(var(--hero-text-gradient));
  -webkit-background-clip: text;
  font-family: Roboto Condensed, sans-serif;
  font-size: 2.5rem;
  line-height: 50px;
}

.contact {
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
  line-height: 1;
  display: flex;
}

.contact a {
  vertical-align: middle;
  height: 25px;
  text-decoration: none;
  display: inline-block;
}

.contact__icon {
  color: var(--text-color);
  width: 1.2rem;
}

.contact__icon--github {
  color: var(--solid-primary);
}

.contact__icon--linkedin {
  color: var(--solid-primary-secondary);
}

.contact__icon--envelope {
  color: var(--solid-secondary);
}

.navigation {
  font-family: Cabin, sans-serif;
}

.navigation__list {
  justify-content: center;
  gap: .875rem;
  padding: 0;
  line-height: 25px;
  list-style: none;
  display: flex;
}

@media (min-width: 800px) {
  .navigation__list {
    flex-direction: column;
  }
}

.navigation__item {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.25rem;
  font-weight: bold;
}

.navigation__item a {
  color: inherit;
  text-decoration: none;
}

.navigation__item a:hover {
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(var(--medium-gradient));
  -webkit-background-clip: text;
  transition: all .2s ease-in;
}

.right {
  box-sizing: border-box;
  flex-basis: auto;
  padding: 0 1rem;
}

@media (min-width: 800px) {
  .right {
    flex-basis: calc(100% - 17rem);
  }
}

.section {
  box-sizing: border-box;
  padding: 1rem 0;
}

.section__heading {
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(var(--faded-medium-gradient));
  -webkit-background-clip: text;
  display: inline-block;
}

.section h2, .section h3 {
  font-family: Roboto Condensed, sans-serif;
  font-size: 2rem;
}

.section h3 {
  font-size: 1.5rem;
}

.section p, .section li {
  color: var(--text-color);
  font-family: Cabin, sans-serif;
  line-height: 1.5;
}

@media (min-width: 800px) {
  .section {
    width: min(100% - 4rem, 60rem);
    min-height: 100vh;
    margin-inline: auto;
  }
}

.hero {
  text-align: center;
  width: min(100vw - 2rem, 30rem);
  height: calc(100vh + -8rem - 100px);
  margin-inline: auto;
  position: relative;
}

@media (min-width: 800px) {
  .hero {
    text-align: left;
    width: min(100% - 4rem, 60rem);
    height: 100vh;
    margin-inline: auto;
  }
}

.hero__text {
  color: var(--text-color);
  line-height: 1.25;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.hero__text--gradient {
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(var(--hero-text-gradient));
  -webkit-background-clip: text;
}

.about__picture {
  float: left;
  padding: 0 1rem .5rem 0;
  display: block;
}

.about__picture img {
  width: 115px;
}

@media (min-width: 800px) {
  .about__picture img {
    width: 135px;
  }
}

.about__content-container {
  margin-top: 2rem;
}

.about__links:before {
  content: " above";
}

@media (min-width: 800px) {
  .about__links:before {
    content: " to the left";
  }
}

.experience__description-list {
  padding: 0;
  list-style: none;
}

.experience__description-list-item {
  margin: 0 0 1rem;
}

.experience__description-list-item--with-border {
  border: 1px solid #000;
  border-radius: 1.25rem;
  padding: .75rem 1rem;
}

.experience__description-paragraph {
  margin: 0 0 .75rem;
}

.projects {
  background-color: #fff;
}

.projects__project-list {
  padding: 0;
  list-style: none;
}

.projects__project-list-item {
  border: 1px solid var(--light-border);
  flex-direction: column;
  margin: 2rem;
  display: flex;
  box-shadow: 5px 5px 28px 5px #0000001a;
}

@media screen and (min-width: 1200px) {
  .projects__project-list-item {
    flex-direction: row;
  }
}

.projects__project-list-item--left {
  background: var(--white);
  flex-basis: 100%;
  padding: 2rem;
}

.projects__project-list-item--right {
  background: var(--medium-background);
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  padding: 2rem;
}

.projects__link-list {
  margin: .5rem 0;
  padding: 0;
  list-style: none;
}

.projects__link-list-item {
  display: inline-block;
}

.projects__link-list-item:not(:last-child):after {
  content: " | ";
}

.projects__link-list-item > a {
  color: inherit;
}

.projects__link-list-item > a:hover {
  background: var(--medium-primary);
  background: -webkit-linear-gradient(var(--hero-text-gradient));
  background: linear-gradient(var(--hero-text-gradient));
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-repeat: repeat;
  background-size: 100%;
  -webkit-background-clip: text;
  background-clip: text;
  transition: all .2s ease-in;
}

.projects__gif {
  width: 100%;
}

@media screen and (min-width: 1200px) {
  .projects__gif--mobile {
    display: none;
  }
}

.projects__gif--desktop {
  display: none;
}

@media screen and (min-width: 1200px) {
  .projects__gif--desktop {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.tech-list {
  margin: .25rem 0;
  padding: 0;
  list-style: none;
}

.tech-list__item--no-logo {
  color: var(--white);
  background: var(--medium-primary);
  background: linear-gradient(var(--faded-medium-gradient));
  border-radius: 20px;
  margin-right: .5rem;
  padding: 3px 12px;
  font-size: .875rem;
  display: inline-block;
}

.tech-list__item--no-logo:last-child {
  margin-right: 0;
}

.tech-list__item--logo {
  vertical-align: middle;
  margin-right: .5rem;
  display: inline-block;
}

.tech-list__item--logo:last-child {
  margin-right: 0;
}

.description-paragraph {
  margin: 0 0 .75rem;
}

.no-margin {
  margin: 0;
}

.delist {
  padding: 0;
  list-style: none;
}

/*# sourceMappingURL=index.14fb3d19.css.map */
