:root {
  --black: #000000;
  --white: #ffffff;
  --text-color: #09203f;
  --light-border: #e8e8e8;
  --medium-background: whitesmoke; // #f5f5f5
  --medium-primary:  #283c86; // primary gradient color
  --faded-primary:  #315B73; // less extreme gradient color
  --solid-primary: #2F5676; // for github icon in footer
  --solid-primary-secondary: #376F67; // for linkedin icon in footer, combination of solid-primary and solid-secondary
  --solid-secondary: #3E8957; // for envelope icon in footer
  --medium-secondary:  #45a247;
  --faded-secondary: #397960;
  --medium-gradient: to left, var(--medium-secondary), var(--medium-primary); // colorful gradient, used in links
  --faded-medium-gradient: to left, var(--faded-secondary), var(--medium-primary); // less obvious gradient
  --hero-text-gradient: to right, var(--faded-secondary), var(--medium-primary);
}

@mixin text-gradient($gradient) {
  background-clip: text;
  color: transparent;
  background-image: linear-gradient($gradient);
  -webkit-background-clip: text;
}

@mixin hero-gradient {
  background: var(--medium-primary);
  background: -webkit-linear-gradient(var(--hero-text-gradient));
  background: linear-gradient(var(--hero-text-gradient));
  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

@mixin width-limiter($smaller-max-width, $max-width) {
  width: min($smaller-max-width, $max-width);
	margin-inline: auto;
}

@mixin delist { // also used in util class
  list-style: none;
  padding: 0;
}

$medium-breakpoint: 800px;
$medium-bp-left-width: 16rem;
$small-bp-left-height: calc(3rem + 50px + 4rem + 25px + 1rem + 25px); // top margin, title, contact margin, contact items, nav margin, nav items // 228px
