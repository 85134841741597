@use "./settings" as settings;

// 100% minus flex-basis for .left minus gap
$medium-bp-right-space: calc(100% - #{settings.$medium-bp-left-width} - 1rem);

.right {
  padding: 0 1rem;
  flex-basis: auto;
  box-sizing: border-box;

  @media (min-width: settings.$medium-breakpoint) {
    flex-basis: $medium-bp-right-space;
  }
}

// right container elements
.section {
  // min-height: 100vh;
  padding: 1rem 0;
  box-sizing: border-box;

  &__heading {
    @include settings.text-gradient(var(--faded-medium-gradient));
    display: inline-block; // makes the gradient more gradient
  }

  & h2,
  h3 {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 2rem;
  }

  & h3 {
    font-size: 1.5rem;
  }

  & p,
  li {
    font-family: 'Cabin', sans-serif;
    color: var(--text-color);
    line-height: 1.5;
  }

  @media (min-width: settings.$medium-breakpoint) {
    min-height: 100vh;
    @include settings.width-limiter(calc(100% - 4rem), 60rem); // take this off if we end up setting a different one for every section, but I feel like we wouldn't?
  }
}

.hero {
  position: relative;
  text-align: center;
  height: calc(100vh - #{settings.$small-bp-left-height});
  @include settings.width-limiter(calc(100vw - 2rem), 30rem); // this one has a smaller width than other sections

  @media (min-width: settings.$medium-breakpoint) {
    text-align: left;
    height: 100vh;
    @include settings.width-limiter(calc(100% - 4rem), 60rem);
  }

  &__text {
    color: var(--text-color);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1.25;

    &--gradient {
      @include settings.text-gradient(var(--hero-text-gradient));
    }
  }
}

.about {

  &__picture {
    display: block;
    padding: 0 1rem 0.5rem 0;
    float: left;

    & img {
      width: 115px;

      @media (min-width: settings.$medium-breakpoint) {
        width: 135px;
      }
    }
  }

  &__content-container {
    margin-top: 2rem;
  }

  // kinda silly but it changes text to explain the location of the links depending on orientation
  // still makes sense if left out
  &__links::before {
    content: " above";

    @media (min-width: settings.$medium-breakpoint) {
      content: " to the left";
    }
  }
}

.experience {

  &__description-list {
    list-style: none;
    padding: 0;
  }

  &__description-list-item {
    margin: 0 0 1rem;

    &--with-border {
      border: 1px solid black;
      border-radius: 1.25rem;
      padding: 0.75rem 1rem;
    }
  }

  &__description-paragraph {
    margin: 0 0 0.75rem;
  }
}

.projects {
  background-color: white;

  &__project-list {
    @include settings.delist;
  }

  &__project-list-item {
    margin: 2rem;
    -webkit-box-shadow: 5px 5px 28px 5px rgba(black, 0.1);
    -moz-box-shadow: 5px 5px 28px 5px rgba(black, 0.1);
    box-shadow: 5px 5px 28px 5px rgba(black, 0.1);
    border: 1px solid var(--light-border);
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1200px) {
      flex-direction: row;
    }

    &--left {
      padding: 2rem;
      background: var(--white);
      flex-basis: 100%;
    }

    &--right {
      padding: 2rem;
      background: var(--medium-background);
      text-align: center; // align img
      width: 100%;
      box-sizing: border-box;
    }
  }

  &__link-list {
    @include settings.delist;
    margin: 0.5rem 0;
  }

  &__link-list-item {
    display: inline-block;

    &:not(:last-child)::after {
      content: " | ";
    }

    > a {
      color: inherit;

      &:hover {
        @include settings.hero-gradient;
        transition: 0.2s ease-in;
      }
    }
  }

  &__gif {
    width: 100%;
    
    &--mobile {

      @media screen and (min-width: 1200px) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @media screen and (min-width: 1200px) {
        display: block;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

// used in multiple sections
.tech-list {
  @include settings.delist;
  margin: 0.25rem 0;

  &__item {
    &--no-logo { // this isn't being used but in case there is a tech that doesn't have a logo we can make styled text
      display: inline-block;
      color: var(--white);
      background: var(--medium-primary);
      background: linear-gradient(var(--faded-medium-gradient));
      font-size: 0.875rem;
      padding: 3px 12px;
      border-radius: 20px;
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }

    &--logo {
      display: inline-block;
      margin-right: 0.5rem;
      vertical-align: middle;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.description-paragraph {
  margin: 0 0 0.75rem;
}
