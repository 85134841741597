@use "./settings" as settings;

.left {
  position: relative;
  flex-basis: auto;
  box-sizing: border-box;

  @media (min-width: settings.$medium-breakpoint) {
    flex-basis: settings.$medium-bp-left-width;
  }
}

// left container elements
.header {
  margin-top: 3rem;
  text-align: center;
  box-sizing: border-box;
  color: var(--text-color);
  
  @media (min-width: settings.$medium-breakpoint) {
    margin-top: 0;
    position: fixed;
    width: 16rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &__primary-link {
    text-decoration: none;
    color: inherit;
  }
}

.title {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 2.5rem;
  line-height: 50px;
  @include settings.text-gradient(var(--hero-text-gradient));
}

.contact {
  margin: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  line-height: 1;

  & a {
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    height: 25px;
  }

  &__icon {
    width: 1.2rem;
    color: var(--text-color);

    &--github {
      color: var(--solid-primary);
    }

    &--linkedin {
      color: var(--solid-primary-secondary);
    }

    &--envelope {
      color: var(--solid-secondary);
    }
  }
}

.navigation {
  font-family: 'Cabin', sans-serif;

  &__list {
    padding: 0;
    display: flex;
    list-style: none;
    gap: 0.875rem;
    justify-content: center;
    line-height: 25px;

    @media (min-width: settings.$medium-breakpoint) {
      flex-direction: column;
    }
  }

  &__item {
    font-size: 1.25rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;

    &--link-text {
      //
    }

    & a {
      text-decoration: none;
      color: inherit;

      &:hover {
        @include settings.text-gradient(var(--medium-gradient));
        transition: 0.2s ease-in;
      }
    }
  }
}
